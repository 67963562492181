import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from '../../contexts/userContext'
import { setJwtToken, setRefreshToken } from '../../JwtTokenManager'

export const SilentConnect = () => {
    const { user, setUser } = React.useContext(UserContext);
    const qboCode = new URLSearchParams(window.location.search).get(
        "qbocode"
    );
    const randNum = new URLSearchParams(window.location.search).get(
        "randnum"
    );
    useEffect(() => {
        doSilentConnect();

    }, [])

    const doSilentConnect = async () => {

        const res = await fetch(`authenticate/connectthisaccount?username=${user.userName}&qbocode=${qboCode}&randNum=${randNum}`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json'
            },

        })

        if (res.ok) {
            const resData = await res.json();

            setUser(resData.userInfo);
            setJwtToken(resData.token);
            setRefreshToken(resData.refreshToken);
            window.location.href = '/'
        }
        else {
            alert("The QBO you are using is either already connected, or something is wrong with our servers. Feel free to reach out to mark@monkbe.com for assistance!");
            window.location.href = '/'
        }

    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}