import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const pTiers = [
    {
        title: 'Monthly',
        subheader: 'Pay as monthly and cancel anytime.',
        price: '50',
        description: [
            "- Access to of RightTool's Free Features",
            "- Enhancements to pages & workflows within QBO",
            '- NO Ads & Elevated Support',
            '- More and more Pro Features being developed every day!',
        ],
        occurance: '/ mo',
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 'test1234'
    },
    {
        title: 'Yearly',
        subheader: 'Save $100 a year when you pay with this option',
        price: '500',
        description: [
            '- More %15 off a year',
            "- Access to of RightTool's Lite Features",
            "- Enhancements to pages/ workflows within QBO",
            '- NO Ads & Elevated Support',
            '- More and more Pro Features being developed every day!',
        ],
        occurance: '/ yr',
        buttonText: 'Get started',
        buttonVariant: 'contained',
        productId: 'test123'
    },
    //{
    //    title: 'Founder Edition',
    //    subheader: 'Join this limited opportunity to become part of the RightTool team',
    //    price: '1000',
    //    description: [
    //        '- Access to All Pro Features',
    //        "- Sneak Peek Beta Features",
    //        "- Access to quarterly meetings to discuss things YOU would like to see in RightTool",
    //        '- Become an investor in RightTool',
    //    ],
    //    occurance: 'one time',
    //    buttonText: 'Get started',
    //    buttonVariant: 'outlined',
    //},
];

export const ProductSelection = ({OnProductSelect, ProductTiers}) => {
    const [tiers, setTiers] = useState(pTiers);

    useEffect(() => {

        let tempTiers = [];

        if (ProductTiers != null) {
            ProductTiers.forEach(t => {

                let tempDescriptions = [];
                var descriptions = t.description.split("|")

                descriptions.forEach(d => tempDescriptions.push(`- ${d}`));

                tempTiers.push({
                    title: t.name,
                    subheader: t.subHeader,
                    price: t.price,
                    description: tempDescriptions,
                    occurance: t.occuranceLabel,
                    buttonText: t.buttonText,
                    buttonVariant: t.buttonVariant,
                    productId: t.stripeProductId
                })
            })
        }

        setTiers(tempTiers);

    }, [ProductTiers])

    const SelectPrice = (productId, priceLabel) => {
        OnProductSelect(productId, priceLabel)
    }
    

    const PricingContent = () => {
        return (
            <React.Fragment>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />
                {/* Hero unit */}
                <Grid sx={{ backgroundColor: 'white' }} container>
                    <Grid item sx={{ marginRight: 'auto', marginLeft: 'auto' }}>
                        <Typography component="h1" variant="h2" style={{ textShadow: '1px 1px 2px lightgrey' }}>
                            RightTool Pro
                        </Typography>
                    </Grid>
                </Grid>
                <Container disableGutters maxWidth="lg" component="main" sx={{
                    pt: 8, pb: 6,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}>

                    <Card fullWidth maxWidth='lg' sx={{ mr: 4 }}>
                            <CardHeader
                                title={
                                    <Typography
                                        component="h1"
                                        variant="h2"
                                        align="center"
                                        color="white"
                                        gutterBottom
                                        style={{ textShadow: '1px 1px 2px lightgrey' }}
                                    >
                                        Monthly Plan
                                    </Typography>
                                }
                                subheader={
                                    <Typography variant="h5" align="center" color="lightgrey" component="p">
                                        Try it for 14 days for free!
                                    </Typography>

                                }
                                titleTypographyProps={{ align: 'center', color: 'white' }}
                                //action={tier.title === 'Yearly' ? <StarIcon /> : null}
                                subheaderTypographyProps={{
                                    align: 'center',
                                }}

                                sx={{
                                    backgroundColor: '#193048'

                                }}
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 0,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${tiers[0].price}
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        {tiers[0].occurance}
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    mb: 2,
                                }}>
                                    <Typography variant="h6" color="text.secondary">
                                        plus $15/mo for each additional user
                                    </Typography>
                                </Box>
                                <Divider />
                                <ul>
                                    {tiers[0].description.map((line) => (
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="start"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant={tiers[0].buttonVariant} onClick={() => { SelectPrice(tiers[0].productId, `$${tiers[0].price}${tiers[0].occurance}`) }}>
                                    {tiers[0].buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    <Card fullWidth maxWidth='lg'>
                            <CardHeader
                                title={
                                    <Typography
                                        component="h1"
                                        variant="h2"
                                        align="center"
                                        color="white"
                                        gutterBottom
                                        style={{ textShadow: '1px 1px 2px lightgrey' }}
                                    >
                                        Yearly Plan
                                    </Typography>
                                }
                            subheader={

                                    <Typography variant="h5" align="center" color="lightgrey" component="p">
                                        Try it for 14 days for free!
                                    </Typography>

                                }
                                titleTypographyProps={{ align: 'center', color: 'white' }}
                                //action={tier.title === 'Yearly' ? <StarIcon /> : null}
                                subheaderTypographyProps={{
                                    align: 'center',
                                }}

                                sx={{
                                    backgroundColor: '#193048'

                                }}
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 0,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${tiers[1].price}
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        {tiers[1].occurance}
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    mb: 2,
                                }}>
                                    <Typography variant="h6" color="text.secondary">
                                        plus $150/yr for each additional user
                                    </Typography>
                                </Box>
                                <Divider />
                                <ul>
                                    {tiers[1].description.map((line) => (
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="start"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant={tiers[1].buttonVariant} onClick={() => { SelectPrice(tiers[1].productId, `$${tiers[1].price}${tiers[1].occurance}`) }}>
                                    {tiers[1].buttonText}
                                </Button>
                            </CardActions>
                        </Card>

                </Container>
                   


                {/* End hero unit */}
                
                {/* Footer */}
                <Container
                    maxWidth="md"
                    component="footer"
                    sx={{
                        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                        mt: 8,
                        py: [3, 6],
                    }}
                >
                    <Grid container spacing={4} justifyContent="space-evenly">
                    </Grid>
                    <Copyright sx={{ mt: 5 }} />
                </Container>
                {/* End footer */}
            </React.Fragment>
        );
    }

    return <PricingContent />;
}