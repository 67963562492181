import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeAuthorizedCall } from '../../JwtTokenManager'
import { BackdropLoader } from '../BackdropLoader';
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import { object, string } from 'yup';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Link from '@mui/material/Link';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://righttool.app">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export const Imports = ({ navigation }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [imports, setImports] = useState([]);
    const [inviteSentPopup, setInviteSentPopup] = useState(false);

    const [currentTeamMemberId, setCurrentTeamMemberId] = useState(0);
    const [openDeletePopup, setDeletePopup] = useState(false);

    const columns = [
        { field: 'customerName', headerName: 'Customer', flex: 1 },
        {
            field: 'id', headerName: 'Actions', width: 200, renderCell: (params) => (

                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Tooltip title="Delete Import">
                        <IconButton
                            size="medium"
                            sx={{ ml: 2, backgroundColor: 'primary.main' }}
                            onClick={() => { handleDeleteClick(params.row) }}

                        >
                            <DeleteIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        },
        {
            field: 'invitAccepted', headerName: 'Invite Accepted', width: 150, renderCell: (params) => (
                <div>
                    {params.value == true &&
                        <Alert severity="success">Accepted</Alert>
                    }
                    {params.value !== true &&
                        <Tooltip title="Click To Send Invite">
                            <Alert severity="warning" onClick={() => { handleInvite(params.row) }}>Pending <ForwardToInboxIcon /></Alert>
                        </Tooltip>
                    }
                </div>

            )
        },

    ];

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pl: 1.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter />
            </Box>
        );
    }

    useEffect(() => {
        getImports();
    }, [])

    const getImports = async () => {
        const response = await makeAuthorizedCall('teamapi/get-import-page', 'get', null);
        const data = await response.json();
        setImports(data.imports.importMembers);
        setIsLoading(false);
    }

    const syncImports = async () => {
        setIsLoading(true);
        const response = await makeAuthorizedCall('teamapi/sync-import', 'get', null);

        getImports();
    }

    const handleDelete = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        var body = JSON.stringify(
            {
                id: currentTeamMemberId,
            });


        await makeAuthorizedCall(`teamapi/delete-import`, 'post', body);

        //reload the grid after delete a member
        getImports();
        setDeletePopup(false);

    };

    const bulkInvite = async () => {
        setIsLoading(true);

        await makeAuthorizedCall(`teamapi/invite-pending-imports`, 'post');
        setIsLoading(false);
        handleInviteSentOpen();

    };

    const handleInvite = async (teamMember) => {
        setIsLoading(true);

        var body = JSON.stringify(
            {
                id: teamMember.id,
            });


        await makeAuthorizedCall(`teamapi/invite-team-member`, 'post', body);

        handleInviteSentOpen();
        setIsLoading(false);
    };

    const handleInviteSentOpen = () => {
        setInviteSentPopup(true);
    }

    const handleInviteSentClose = () => {
        setInviteSentPopup(false)
    }

    const InviteTeamMemberPopUp = () => {
        return (
            <Dialog open={inviteSentPopup} onClose={handleInviteSentClose}>

                <DialogContent>
                    <Typography component="h1" variant="h5">
                        Invites Sent!
                    </Typography>
                    <Box sx={{ mt: 3, mx: 5 }}>
                        <DialogActions>
                            <Button onClick={handleInviteSentClose}>Dismiss</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        )

    }

    const handleDeleteClick = (teamMember) => {

        setCurrentTeamMemberId(teamMember.id);
        handleDeleteOpen();

    };

    const handleDeleteOpen = () => {
        setDeletePopup(true);
    }

    const handleDeleteClose = () => {
        setDeletePopup(false)
        setCurrentTeamMemberId(0);
    }


    const DeleteTeamMemberPopUp = () => {
        return (
            <Dialog open={openDeletePopup} onClose={handleDeleteClose}>

                <DialogContent>
                    <Typography component="h1" variant="h5">
                        Are you sure want to delete this import? This action will also cancel the imported user's Stripe Subscription
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleDelete} sx={{ mt: 3, mx: 5 }}>
                        <DialogActions>
                            <Button onClick={handleDeleteClose}>Nevermind</Button>
                            <Button variant="contained" type="submit">Delete Import</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        )

    }

    return (
        <div>
            {
                isLoading &&
                <BackdropLoader isLoading={isLoading} />
            }
            {
                !isLoading &&
                <div>
                        <Container disableGutters fullWidth>
                            <Typography
                                component="h1"
                                variant="h2"
                                align="left"
                                color="text.main"
                            >
                                Imports
                            </Typography>
                        </Container>
                        <Grid item xs={12} sx={{ py: 2 }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row', backgroundColor: '#193048', justifyContent: "flex-end" }}>
                                <Tooltip title="Bulk Send Invites To Pending Imports">
                                    <IconButton
                                        size="medium"
                                        sx={{ mr: 2, backgroundColor: 'white' }}
                                        onClick={bulkInvite}
                                    >
                                        <ForwardToInboxIcon sx={{ color: 'primary.main', '&:hover': {color : 'white'} }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Retrieve users from Stripe that are not in the RightTool Web Portal">
                                    <Button variant="outlined" sx={{ color: 'white' }} size="small" onClick={syncImports}>Sync With Stripe</Button>
                                </Tooltip>                             
                            </Paper>
                        </Grid>
                        <div style={{ height: 500, width: '100%' }}>

                            <DataGrid
                                rows={imports}
                                columns={columns}
                                components={{ Toolbar: QuickSearchToolbar }}
                                sx={{
                                    '& .MuiDataGrid-row:hover': {
                                        color: 'primary.main',
                                        backgroundColor: 'lightgray'
                                    },
                                }}
                            />
                        </div>
                        {InviteTeamMemberPopUp()}
                        {DeleteTeamMemberPopUp() }
                </div>
            }
        </div>
    );

}