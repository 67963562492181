import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



export const BackdropLoader = ({ isLoading }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(isLoading);
        // await getRegisterPageKey();


    }, [isLoading])

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={Boolean(loading)}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        )
}