import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from '../../contexts/userContext'
import { setJwtToken, setRefreshToken } from '../../JwtTokenManager'

export const SilentLogin = () => {
    const { user, setUser } = React.useContext(UserContext);
    const ssoToken = new URLSearchParams(window.location.search).get(
        "ssoToken"
    );
    const registerPage = new URLSearchParams(window.location.search).get(
        "registerPage"
    );
    const promoCode = new URLSearchParams(window.location.search).get(
        "promoCode"
    );
    useEffect(() => {
         doSilentLogin();

    }, [])

    const doSilentLogin = async () => {

        const res = await fetch(`authenticate/sso?ssoToken=${ssoToken}&register=${registerPage}&promoCode=${promoCode}`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json'
            },

        })

        if (res.ok) {
            const resData = await res.json();

            setUser(resData.userInfo);
            setJwtToken(resData.token);
            setRefreshToken(resData.refreshToken);
            window.location.href = '/'
        }
        else {
            window.location.href = '/'
        }

    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}