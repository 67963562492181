import React, { useEffect, useState } from 'react';
import { saveUser, deleteUser } from '../src/contexts/userContext'

export function getJwtToken() {
    return localStorage.getItem("jwt")
}

export function setJwtToken(token) {
    localStorage.setItem("jwt", token)
}

export function deleteJwtToken() {
    localStorage.removeItem("jwt");
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken() {
    return localStorage.getItem("refreshToken")
}

export function setRefreshToken(token) {
    localStorage.setItem("refreshToken", token)
}

export function deleteRefreshToken() {
    localStorage.removeItem("refreshToken");
}



export async function makeAuthorizedCall(url, method, body, alreadyRefreshed, newToken, hasCalled = false) {

    //seems like the old token is getting pulled here after token refresh. 
    //To avoid the issue we are passing the new Token after refreshing.
    var token = newToken == null ? getJwtToken() : newToken;



    if (body != null) {
        const res = await fetch(url, {
            method: method,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: body
        });

        if (res.ok) {
            return res;
        }
        else {
            if (!hasCalled) {
                var refreshResponse = await getTokenRefresh()
                if (refreshResponse.success) {
                    var refreshedCall = await makeAuthorizedCall(url, method, body, refreshResponse.alreadyRefeshed, refreshResponse.newToken, true);

                return refreshedCall;
            }
            }
            else {
                window.location.href = '/logout'
            }
        }

        
    }
    else {
        const res = await fetch(url, {
            method: method,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })

        if (res.ok) {
            return res;
        }
        else {
            var refreshResponse = await getTokenRefresh()
            if (refreshResponse.success) {
                var refreshedCall = await makeAuthorizedCall(url, method, body, refreshResponse.alreadyRefeshed, refreshResponse.newToken);

                return refreshedCall;
            }
            else {
                window.location.href = '/logout'
            }
        }
    }
}

async function getTokenRefresh() {

    var token = getJwtToken();
    var refreshToken = getRefreshToken();

    var body = JSON.stringify(
        {
            token: token,
            refreshToken: refreshToken

        });

    const res = await fetch('Authenticate/refresh', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: body
    });

    if (res.ok) {
        const resData = await res.json();

        if (!resData.alreadyRefreshed) {
            setJwtToken(resData.token);
            setRefreshToken(resData.refreshToken);
            saveUser(resData.userInfo);

            return {
                success: true,
                alreadyRefeshed: false,
                newToken: resData.token }
        }
        else {
            return { success: true, alreadyRefeshed: true, newToken: null}
        }

    }
    else {
        return { success: false, alreadyRefeshed: false, newToken: null }
    }
}


