import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme } from '../../muiTheme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';



export const Checkout = ({ StripePromise, ClientSecret, Trial, SsoToken, PriceLabel, RegisterPage, PromoCodes, PriceId }) => {

    // Get the lookup key for the price from the previous page redirect.
    const [clientSecret, setClientSecret] = useState();
    const [name, setName] = useState('');
    const [messages, _setMessages] = useState('');
    const [paymentIntent, setPaymentIntent] = useState();
    const [stripePromise, setStipePromise] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [trial, setTrial] = useState(false);
    const [ssoToken, setSSOToken] = useState(null);
    const [priceLabel, setPriceLabel] = useState('');
    const [originalLabel, setOriginalLabel] = useState('');
    const [dateString, setDateString] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeSuccess, setPromoCodeSuccess] = useState('');
    const [promoCodes, setPromoCodes] = useState([]);
    const [priceId, setPriceId] = useState(null);

    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();


    useEffect(() => {
        if (StripePromise != null) {
            setStipePromise(StripePromise);
        }
        if (stripePromise == null && StripePromise == null) {
            getPublishableKey();
        }

        var today = new Date(new Date())

        if (trial) {
            today = new Date(new Date().getTime() + (86400000 * 14));
        }
            

        setDateString(`${parseInt(today.getMonth() + 1)}/${today.getDate()}/${today.getFullYear().toString().substr(-2) }`)
        
    }, [])

    useEffect(() => {
        if (StripePromise != null) {
            setStipePromise(StripePromise);
        }
        if (stripePromise == null && StripePromise == null) {
            getPublishableKey();
        }

    }, [StripePromise])

    useEffect(() => {
        setPriceLabel(PriceLabel);
        setOriginalLabel(PriceLabel);
    }, [PriceLabel])

    useEffect(() => {
        setPromoCodes(PromoCodes);
    }, [PromoCodes])

    useEffect(() => {
        setPriceId(PriceId);
    }, [PriceId])

    useEffect(() => {
        if (ClientSecret != null) {
            setClientSecret(ClientSecret);
        }

    }, [ClientSecret])

    useEffect(() => {
        if (SsoToken != null) {
            setSSOToken(SsoToken);
        }

    }, [SsoToken])

    useEffect(() => {

        setTrial(Trial);

        if (Trial) {
            var today = new Date(new Date().getTime() + (86400000 * 14));
            setDateString(`${parseInt(today.getMonth() + 1)}/${today.getDate()}/${today.getFullYear().toString().substr(-2)}`)
        }


        

    }, [Trial])

    const getPublishableKey = async () => {
        const response = await fetch('/stripe/get-publishable-key', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        setStipePromise(loadStripe(data.publishableKey));
        
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        var returnUrl = RegisterPage ? `${window.location.origin}/silentlogin?ssoToken=${ssoToken}&registerPage=${RegisterPage}&promoCode=${promoCode == '' ? 'No Promo' : promoCode}` : `${window.location.origin}/processCheckout?action=activate&promoCode=${promoCode == '' ? 'No Promo' : promoCode}`;

        if (!trial) {

            let { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: returnUrl,
                },
            });

            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        }
        else {
            const { error } = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setMessage(error.message);
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        }

    }

    const promoCodeChange = (event) => {
        setPromoCode(event.target.value);

        var activeCode = promoCodes.find(code => code.name == event.target.value && code.stripeProductId == priceId);

        if (activeCode !== undefined) {
            setPriceLabel(activeCode.successPrice);
            setPromoCodeSuccess(activeCode.successMessage);
        }
        else {
            setPriceLabel(originalLabel)
            setPromoCodeSuccess("")
            
        }
    }

    

    if (paymentIntent && paymentIntent.status === 'succeeded') {
        //return <Redirect to={{ pathname: '/account' }} />
    }

    return (

                    <Container component="form" id="payment-form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width:'75%'}}>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'top',
                                mb: 2,
                            }}
                        >
                            <Typography variant="h6" color="text.secondary">
                    You will be charged {priceLabel} beginning on {dateString}
                            </Typography>
                        </Box>
                        {promoCodes && 
                            <div>
                                <Grid item xs={12}>
                                    <Typography color="text.secondary">
                                        Have a promo code? Add it here!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ paddingTop: 2 }}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="promocode"
                                        required
                                        fullWidth
                                        value={promoCode}
                                        label="Promo Code"
                                        onChange={promoCodeChange}
                                    />
                                </Grid>
                                {promoCodeSuccess != "" &&
                                    <Grid item xs={12}>
                                        <Alert severity="success">{promoCodeSuccess}</Alert>
                                    </Grid>
                                }
                            </div>

                        }
                        <PaymentElement id="payment-element" />
                        <Button
                            disabled={isLoading || !stripe || !elements}
                            type="submit"
                            id="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            <span id="button-text">
                                {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                            </span>
                        </Button>
                        {/* Show any error or success messages */}
            {message && <Grid item xs={12}>
                <Alert severity="error">{message}</Alert>
            </Grid>}
                    </Container>

    )
}