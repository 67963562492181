import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { object, string } from 'yup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ProductSelection } from '../Checkout/ProductSelection'
import { loadStripe } from '@stripe/stripe-js';
import { BackdropLoader } from '../BackdropLoader';
import { Elements } from "@stripe/react-stripe-js";
import {Checkout } from '../Checkout/Checkout'
import { theme } from '../../muiTheme'
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

import StepLabel from '@mui/material/StepLabel';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://righttool.app">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

let formSchema = object({
    name: string().required('!name|Name Is Required'),
    userName: string().required('!userName|User Name Is Required').test('is unique', '!userName|User Name Already Exists', async (value, testContext) => (await fetch(`/authenticate/user-name-unique?userName=${value}`, { method: "get" })).text !== 'true').matches(
        /^\S*$/,
        "!userName|User Name Can Not Have Spaces"
    ),
    email: string().email('!email|Email Is Not In A Correct Format').required('!email|Email Is Required'),
    password: string().required().min(8).matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
    //userRole: string().required('!userRole|User Role Is Required').matches(/(Admin|Advertiser|User)/),
    companyName: string().required('!companyName|Company Name Is Required')
});


export const Register = () => {
    const [role, setRole] = React.useState('');
    const [nameError, setNameError] = React.useState('');
    const [userNameError, setUserNameError] = React.useState('');
    const [companyNameError, setCompanyError] = React.useState('');
    const [emailAddressError, setEmailAddressError] = React.useState('');
    const [roleError, setRoleError] = React.useState('');
    const [pwEight, setPwEight] = React.useState(false);
    const [pwUpper, setPwUpper] = React.useState(false);
    const [pwNumber, setPwNumber] = React.useState(false)
    const [pwSpecial, setPwSpecial] = React.useState(false);
    const [pwLower, setPwLower] = React.useState(false);
    const [priceId, setPriceId] = React.useState(null);
    const [stripePromise, setStipePromise] = useState(null);
    const [stripeProducts, setStripeProducts] = useState(null);
    const [loading, setLoading] = useState(true)
    const [clientSecret, setClientSecret] = useState(null)
    const [ssoToken, setSSOToken] = useState(null)
    const [activeStep, setActiveStep] = useState(0)
    const [priceLabel, setPriceLabel] = useState('')
    const [promoCodes, setPromoCodes] = useState([])
    const [pwMatch, setPwMatch] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [confirmpassword, setConfirmPassword] = React.useState('');
    const searchParams = new URLSearchParams(window.location.search);
    const prefillUser = searchParams.get("prefillUser");
    const prefillName = searchParams.get("prefillName");
    const prefillEmail = searchParams.get("prefillEmail");
    const prefillQbo = searchParams.get("prefillQbo");

    useEffect(() => {
        getRegisterPageInfo();
    }, [])

    const getRegisterPageInfo = async () => {
        const response = await fetch('/stripe/get-register-page', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        })

        const data = await response.json();

        if (stripePromise == null) {
            setStipePromise(loadStripe(data.publishableKey));
        }

        setStripeProducts(data.products);
        setPromoCodes(data.promoCodes);
        setLoading(false);
        console.log(window.location.origin);
    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        setNameError('');
        setUserNameError('');
        setEmailAddressError('');
        //setRoleError('');
        setCompanyError('');

        const data = new FormData(event.currentTarget);

        try {
            await formSchema.validate(
                {
                    name: data.get('name'),
                    userName: data.get('userName'),
                    email: data.get('email'),
                    password: data.get('password'),
                    //userRole: role
                    companyName: data.get('companyName')
                },
                {
                    strict: true,
                    abortEarly: false
                },
            );

            if (password == confirmpassword) {
                var body = JSON.stringify(
                    {
                        name: data.get('name'),
                        userName: data.get('userName'),
                        email: data.get('email'),
                        password: data.get('password'),
                        companyName: data.get('companyName'),
                        qboSub: data.get('qboIdentity'),
                        priceId: priceId

                    });

                //var url = ''

                //if (role === "Admin") {
                //    url = `authenticate/register-admin`;
                //}
                //else if (role === "Advertiser") {
                //    url = `authenticate/register-advertiser`;
                //}
                //else {
                //    url = `authenticate/register-user`;
                //}

                const res = await fetch(`authenticate/register-user`, {
                    method: "post",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: body
                })
                const resData = await res.json();

                setSSOToken(resData.ssoToken);
                setClientSecret(resData.clientSecret)
                handleNext();
                setLoading(false)
            }
            else {
                setLoading(false);
            }


        } catch (err) {
            //err.name; // => 'ValidationError'
            //err.errors; // => ['Deve ser maior que 18']
            console.log(JSON.stringify(err.name))

            console.log(JSON.stringify(err.errors))
            handleErrors(err.errors);
            setLoading(false);
        }    

    };


    //const handleChange = (event) => {
    //    setRole(event.target.value);
    //};

    const passwordCheck = (event) => {
        var val = event.target.value;
        setPassword(event.target.value)

        if (val.length >= 8)
            setPwEight(true);
        else {
            setPwEight(false);
        }

        if (/[A-Z]/.test(val)) {
            setPwUpper(true)
        }
        else {
            setPwUpper(false)
        }

        if (/[a-z]/.test(val)) {
            setPwLower(true)
        }
        else {
            setPwLower(false)
        }

        if (/[!@#$%^&*()\-_=+{};:,<.>`~]/.test(val)) {
            setPwSpecial(true)
        }
        else {
            setPwSpecial(false)
        }

        if (/.*\d/.test(val)) {
            setPwNumber(true)
        }
        else {
            setPwNumber(false)
        }

        if (val === confirmpassword) {
            setPwMatch(true)
        }
        else {
            setPwMatch(false)
        }

    };

    const confirmCheck = (event) => {
        var val = event.target.value;
        setConfirmPassword(event.target.value)


        if (val === password) {
            setPwMatch(true)
        }
        else {
            setPwMatch(false)
        }

    };

    const userNameCheck = async (event) => {

        var val = event.target.value;
        const res = await fetch(`authenticate/user-name-unique?userName=${val}`, {
                    method: "get"
                })
        const unique = await res.text();

        console.log(`response : ${unique}`)


        if (unique === 'true') {
            setUserNameError('')
        }
        else {
            setUserNameError('User Name Already Exists')
        }
    }

    const handleErrors = (errors) => {

        errors.forEach(e => {

            if (e.startsWith('!')) {
                var message = e.substring(1);
                var messageEnum = message.split('|');

                switch (messageEnum[0]) {
                    case "userName":
                        setUserNameError(messageEnum[1]);
                        break;
                    case "email":
                        setEmailAddressError(messageEnum[1]);
                        break;
                    case "userRole":
                        setRoleError(messageEnum[1]);
                        break;
                    case "companyName":
                        setCompanyError(messageEnum[1]);
                    case "name":
                        setNameError(messageEnum[1]);
                }

            }
        });
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const SelectProduct = (productId, priceLabel) => {
        console.log(`product selected ${productId}`)
        setPriceId(productId);
        setPriceLabel(priceLabel);
    }

    const appearance = {
        theme: 'flat',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const steps = ['Account Setup', 'Payment details'];


    if (stripePromise)
        return (
            <ThemeProvider theme={theme}>
                <BackdropLoader isLoading={loading} />
                {priceId == null && <ProductSelection OnProductSelect={SelectProduct} ProductTiers={stripeProducts} />}
                {(priceId != null) &&
                        <Container component="main">
                            <CssBaseline />
                            <Paper
                                elevation={3}
                                sx={{
                                    marginTop: 8,
                                    marginX: 20,
                                    paddingY: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                {steps[activeStep]}
                                </Typography>
                            <Stepper activeStep={activeStep} style={{width: '70%', paddingTop:20, paddingBottom:20}}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                            </Stepper>
                            {activeStep == 0 && 
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, mx: 5 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="companyName"
                                                required
                                                fullWidth
                                                id="companyName"
                                                label="Company Name"
                                                defaultValue={ prefillUser }
                                                autoFocus
                                            />
                                        </Grid>
                                        {companyNameError != '' &&
                                            <Grid item xs={12}>
                                                <Alert severity="error">{companyNameError}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="name"
                                                required
                                                fullWidth
                                                id="name"
                                                defaultValue={ prefillName }
                                                label="Name"
                                            />
                                        </Grid>
                                        {nameError != '' &&
                                            <Grid item xs={12}>
                                                <Alert severity="error">{nameError}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="userName"
                                                required
                                                fullWidth
                                                autoComplete="username"
                                                id="userName"
                                                label="User Name"
                                                defaultValue={ prefillEmail }
                                                onChange={userNameCheck}
                                            />
                                        </Grid>
                                        {userNameError != '' &&
                                            <Grid item xs={12}>
                                                <Alert severity="error">{userNameError}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email Address"
                                                name="email"

                                                autoComplete="email"
                                                defaultValue={ prefillEmail }
                                            />
                                        </Grid>
                                        {emailAddressError != '' &&
                                            <Grid item xs={12}>
                                                <Alert severity="error">{emailAddressError}</Alert>
                                            </Grid>
                                        }
                                        {/*<Grid item xs={12}>*/}
                                        {/*    <FormControl fullWidth>*/}
                                        {/*        <InputLabel id="demo-simple-select-label">Role</InputLabel>*/}
                                        {/*        <Select*/}
                                        {/*            labelId="demo-simple-select-label"*/}
                                        {/*            id="demo-simple-select"*/}
                                        {/*            value={role}*/}
                                        {/*            label="Role"*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*        >*/}
                                        {/*            <MenuItem value={"Admin"}>Admin</MenuItem>*/}
                                        {/*            <MenuItem value={"Advertiser"}>Advertiser</MenuItem>*/}
                                        {/*            <MenuItem value={"User"}>User</MenuItem>*/}
                                        {/*        </Select>*/}
                                        {/*    </FormControl>*/}
                                        {/*</Grid>*/}
                                        {/*{roleError != '' &&*/}
                                        {/*    <Grid item xs={12}>*/}
                                        {/*        <Alert severity="error">{roleError}</Alert>*/}
                                        {/*    </Grid>*/}
                                        {/*}*/}
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type="password"
                                                id="password"
                                                autoComplete="new-password"
                                                onKeyUp={passwordCheck}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                type="password"
                                                id="confirmPassword"
                                                onChange={confirmCheck}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="QBO Identity"
                                                name="qboIdentity"
                                                id="qboIdentity"
                                                defaultValue={prefillQbo}
                                                hidden
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {!pwEight && <Alert severity="warning">Password Must Have 8 or More Characters</Alert>}
                                            {pwEight && <Alert severity="success">Password Must Have 8 or More Characters</Alert>}
                                            {!pwUpper && <Alert severity="warning">Password Must Contain Upper Case</Alert>}
                                            {pwUpper && <Alert severity="success">Password Must Contain Upper Case</Alert>}
                                            {!pwLower && <Alert severity="warning">Password Must Contain Lower Case</Alert>}
                                            {pwLower && <Alert severity="success">Password Must Contain Lower Case</Alert>}
                                            {!pwNumber && <Alert severity="warning">Password Must Contain A Number</Alert>}
                                            {pwNumber && <Alert severity="success">Password Must Contain A Number</Alert>}
                                            {!pwSpecial && <Alert severity="warning">Password Must Contain A Special Character</Alert>}
                                            {pwSpecial && <Alert severity="success">Password Must Contain A Special Character</Alert>}
                                            {!pwMatch && <Alert severity="warning">Password Does Not Match Confirm Password</Alert>}
                                            {pwMatch && <Alert severity="success">Password Matches Confirm Password</Alert>}
                                        </Grid>
                                        {/*<Grid item xs={12}>*/}
                                        {/*    <FormControlLabel*/}
                                        {/*        control={<Checkbox value="allowExtraEmails" color="primary" />}*/}
                                        {/*        label="I want to receive inspiration, marketing promotions and updates via email."*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Continue
                                    </Button>
                                    {/*<Grid container justifyContent="flex-end">*/}
                                    {/*    <Grid item>*/}
                                    {/*        <Link href="/" variant="body2">*/}
                                    {/*            Already have an account? Sign in*/}
                                    {/*        </Link>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}
                                </Box>
                            }
                            {activeStep == 1 &&
                                <Elements stripe={stripePromise} options={options}>
                                    <Checkout StripePromise={stripePromise} ClientSecret={clientSecret} Trial={true} SsoToken={ssoToken} PriceLabel={priceLabel} RegisterPage={true} PromoCodes={promoCodes} PriceId={priceId} />
                                </Elements>
                            }
                            </Paper>
                        <Copyright sx={{ mt: 5 }} />
                    </Container>
                }
            </ThemeProvider>
        );

    else {
        return (
            <ThemeProvider theme={theme}>
                <BackdropLoader isLoading={true} />
            </ThemeProvider>
            );
    }
}