import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from '../NavMenus/User/NavMenu';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {theme} from '../../muiTheme'

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>
        <NavMenu />
              <Box
                  component="main"
                  sx={{
                      backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                              ? theme.palette.grey[100]
                              : theme.palette.grey[900],
                      flexGrow: 1,
                      height: '100vh',
                      overflow: 'auto',
                  }}
                >
                    <Container style={{paddingTop:75}}>
                        {this.props.children}
                    </Container>
                  
              </Box>
            </Box>
        </ThemeProvider >
    );
  }
}
