import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ThemeProvider } from '@mui/material/styles';
import { object, string } from 'yup';
import { UserContext } from '../../contexts/userContext'
import { setJwtToken, setRefreshToken } from '../../JwtTokenManager'
import { theme } from '../../muiTheme'
import { grey } from '@mui/material/colors';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://righttool.app">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


let formSchema = object({
    userName: string().required('!userName|User Name Is Required'),
    password: string().required('!password|Password Is Required'),
});

export const Login = () => {
    const { user, setUser } = React.useContext(UserContext);
    const [userNameError, setUserNameError] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [generalError, setGeneralError] = React.useState('');
    const [openPasswordReset, setOpenPasswordReset] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [resetPasswordError, setResetPasswordError] = React.useState('');

    const [forgotOpen, setForgotOpen] = React.useState(false);
    const [forgotEmail, setForgotEmail] = React.useState('');
    const [forgotEmailError, setForgotEmailError] = React.useState('');


    const [confirmationText, setConfirmationText] = React.useState('');

    const handleSubmit = async(event) => {
        event.preventDefault();
        setUserNameError('');
        setPasswordError('');
        setGeneralError('');

        try {
            const data = new FormData(event.currentTarget);

            await formSchema.validate(
                {
                    userName: data.get('userName'),
                    password: data.get('password'),
                },
                {
                    strict: true,
                    abortEarly: false
                },
            );

            var body = JSON.stringify(
                {
                    userName: data.get('userName'),
                    password: data.get('password')

                });

            const res = await fetch(`Authenticate/login`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            const resData = await res.json();
            if (res.ok) {
                setUser(resData.userInfo);
                setJwtToken(resData.token);
                setRefreshToken(resData.refreshToken);
            }
            else {
                setGeneralError('Login Attempt Failed')
            }



        } catch (err) {
            //err.name; // => 'ValidationError'
            //err.errors; // => ['Deve ser maior que 18']
            console.log(JSON.stringify(err.name))

            console.log(JSON.stringify(err.errors))
            handleErrors(err.errors);
        }    
        
    };

    const handleErrors = (errors) => {
        errors.forEach(e => {

            if (e.startsWith('!')) {
                var message = e.substring(1);
                var messageEnum = message.split('|');

                switch (messageEnum[0]) {
                    case "userName":
                        setUserNameError(messageEnum[1]);
                        break;
                    case "password":
                        setPasswordError(messageEnum[1]);
                        break;
                }

            }
        });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
        setResetPasswordError("");
    };

    const handleChange = (event) => {
        setUserName(event.target.value);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
        setResetPasswordError("");
    };

    const resetPassword = async () => {

        if (userName == "" || userName == null) {
            setResetPasswordError("Username is required")
        }
        else {

            setResetPasswordError("");
            const response = await fetch(`Authenticate/request-reset-password?userName=${userName}`, {
                method: "get",
            })

            setOpen(false)
            setConfirmationText("The email registered to your user should have recieved an email link to reset your password. If you do not see an email, please check your spam/junk folder.");
            setConfirmOpen(true)
        }


    }

    const requestPasswordResetPopup = () => {
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Reset Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your username.
                    </DialogContentText>
                    <TextField

                        margin="dense"
                        id="name"
                        label="User Name"
                        multiline
                        fullWidth
                        value={userName}
                        onChange={handleChange}
                        variant="standard"
                    />
                    {resetPasswordError != '' &&
                        <Alert severity="error">{resetPasswordError}</Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Nevermind</Button>
                    <Button onClick={resetPassword}>Reset Password</Button>
                </DialogActions>
            </Dialog>
            );
    }


    const handleForgotClose = () => {
        setForgotOpen(false);
    };

    const handleForgotOpen = () => {
        setForgotOpen(true);
        setForgotEmailError("");
    };

    const handleEmailChange = (event) => {
        setForgotEmail(event.target.value);
    };


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const getForgotUserName = async () => {
        if (forgotEmail == "" || forgotEmail == null) {
            setForgotEmailError("Email is required")
        }
        else if (!validateEmail(forgotEmail)) {
            setForgotEmailError("A Valid Email is Required")
        }
        else {

            setResetPasswordError("");
            const response = await fetch(`Authenticate/request-forgot-username?email=${forgotEmail}`, {
                method: "get",
            })

            setForgotOpen(false)
            setConfirmationText("The email registered to your user should have recieved the username(s) associated with this email. If you do not see an email, please check your spam/junk folder.");
            setConfirmOpen(true)
        }
    }

    const requestForgotPopup = () => {
        return (
            <Dialog open={forgotOpen} onClose={handleForgotClose}>
                <DialogTitle>Forgot User Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the email associated with your account.
                    </DialogContentText>
                    <TextField

                        margin="dense"
                        id="name"
                        label="Email"
                        multiline
                        fullWidth
                        value={forgotEmail}
                        onChange={handleEmailChange}
                        variant="standard"
                    />
                    {forgotEmailError != '' &&
                        <Alert severity="error">{forgotEmailError}</Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleForgotClose}>Nevermind</Button>
                    <Button onClick={getForgotUserName}>Get UserName</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const openSignInWithIntuit = () => {
        window.location.replace("/authenticate/initiate-qbo-auth");
    }

    const requestConfirmation = () => 
    {
        return (
            <div>

                <Dialog open={confirmOpen} onClose={handleConfirmClose}>
                    <DialogTitle>Check Your Inbox</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {confirmationText }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline />
                <Grid sx={{backgroundColor:'white'}} container>
                    <Grid item sx={{ marginRight: 'auto', marginLeft: 'auto' }}>
                        <Link href="https://chrome.google.com/webstore/detail/righttool-for-quickbooks/ocgmjfcfjilnmobeocoklcbpcanilpjd" target="_blank" sx={{
                            textAlign: 'center',
                            color: 'red'
                        }} variant="h4">
                            {"New User? Click here to get the extension!"}
                        </Link>
                    </Grid>
                </Grid>
                <Paper elevation={3}
                        sx={{
                            marginTop: 1,
                            marginX: 20,
                            paddingY: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',                         
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                        Sign in

                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="userName"
                                        label="User Name"
                                        name="userName"
                                        autoFocus
                                    />
                                </Grid>
                                {userNameError != '' &&
                                    <Grid item xs={12}>
                                        <Alert severity="error">{userNameError}</Alert>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                </Grid>
                                {passwordError != '' &&
                                    <Grid item xs={12}>
                                        <Alert severity="error">{passwordError}</Alert>
                                    </Grid>
                                }
                            </Grid>

                            {/*<FormControlLabel*/}
                            {/*    control={<Checkbox value="remember" color="primary" />}*/}
                            {/*    label="Remember me"*/}
                            {/*/>*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 0 }}
                            >
                                Sign In
                        </Button>
                        <img style={{ width: '50%', display: 'block', marginTop: '10px', marginBottom: '10px', cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto' }}
                            src={require('../../assets/signIn.svg').default}
                            onMouseOver={e => e.currentTarget.src = require('../../assets/signInHover.svg').default }
                            onMouseOut={e => e.currentTarget.src = require('../../assets/signIn.svg').default}
                            alt='Sign in with Intuit'
                            onClick={openSignInWithIntuit} />
                            {generalError != '' &&
                                <Grid item xs={12}>
                                    <Alert severity="error">{generalError}</Alert>
                                </Grid>
                            }
                            <Grid container>
                                <Grid item xs>
                                <Link onClick={handleOpen} variant="body2" style={{ cursor: 'pointer' } }>
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link onClick={handleForgotOpen} variant="body2" style={{ cursor: 'pointer' }}>
                                        Forgot username?
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                </Paper>
                <Grid container>
                    <Grid item xs>
                    </Grid>
                    <Grid item sx={{ marginRight: 20 }} >
                        <Link href="/register" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid>
                {requestPasswordResetPopup()}
                {requestConfirmation()}
                {requestForgotPopup()}
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
        </ThemeProvider>
    );
}