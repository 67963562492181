import { ThemeProvider, createTheme, extendTheme } from '@mui/material/styles';
import {grey} from '@mui/material/colors';

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        primary: {
            ...'#fff',
            ...(mode === 'dark' ?
                { main: '#fff', } :
                { main: '#193048', }),
        },
        ...(mode === 'dark' ?
            {
                background: {
                    default: '#193048',
                    paper: '#193048',
                },
            } :

            {
                background: {
                    default: '#fff',
                    paper: '#fff',
                },
            }
            
            ),
        text: {
            ...(mode === 'light'
                ? {
                    primary: '#193048',
                    secondary: grey[800],
                }
                : {
                    primary: '#fff',
                    secondary: grey[300],
                }),
        },

        action: {
            ...(mode === 'light'
                ? {
                    active: '#193048',
                    hover: grey[300],
                    selected: '#193048',
                    disabled: '#193048',
                    disabledBackground: '#193048'

                }
                : {
                    active: '#193048',
                    hover: grey[300],
                    selected: '#193048',
                    disabled: '#193048',
                    disabledBackground: '#193048'
                }),
        },
    },
});

export const theme = createTheme(getDesignTokens('light'))