import React, { useEffect, useState, useContext } from 'react';
import { makeAuthorizedCall } from '../../JwtTokenManager'
import { BackdropLoader } from '../BackdropLoader';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { UserContext } from '../../contexts/userContext'


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://righttool.app">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export const DisconnectAccount = ({ navigation }) => {

    const [isLoading, setIsLoading] = useState(true);
    const { user, setUser } = useContext(UserContext);
    const [ userRefreshed, setUserRefreshed ] = useState(false);

    useEffect(() => {
        //if (!userRefreshed) {
        //    updateUser();
        //}
        
        setIsLoading(false);

    }, [])

    const updateUser = async () => {
    

        const response = await makeAuthorizedCall('Authenticate/refresh-user-info', 'get', null);
        const data = await response.json();
        setUser(data.user);
        setUserRefreshed(true);
    }

    const goToProfile = async () => {

        setIsLoading(true);

        await updateUser();

        window.location.href = '/'
    }


    return (
        <div>
            {
                isLoading &&
                <BackdropLoader isLoading={isLoading} />
            }
            {
                !isLoading &&
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid item xs={12}>
                        <Card sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginTop: '15px' }}>
                                <Typography component="h6" variant="h4" gutterBottom align="center">
                                        Disconnect Successful!
                                </Typography>

                            </div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    mb: 2,
                                }}
                            >
                                    <Button style={{ marginLeft: 'auto', marginRight: 'auto' }} variant="contained" onClick={() => goToProfile()}>Back To Profile</Button>
                            </Box>
                        </Card>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            }
        </div>
    );

}