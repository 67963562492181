import { Login } from "../components/Auth/Login";
import { Register } from "../components/Auth/Register";
import { PasswordReset } from "../components/Auth/PasswordReset";
import { SilentLogin } from "../components/Auth/SilentLogin"
import { Invite } from "../components/Auth/Invite"



const AuthRoutes = [
    {
        index: true,
        element: <Login />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/register',
        element: <Register />
    },
    {
        path: '/silentlogin',
        element: <SilentLogin />
    },
    {
        path: '/passwordreset',
        element: <PasswordReset />
    },
    {
        path: '/invite',
        element: <Invite />
    },
];

export default AuthRoutes;