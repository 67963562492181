import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './Routes/AppRoutes';
import AdminRoutes from './Routes/AdminRoutes';
import AuthRoutes from './Routes/AuthRoutes';
import { Layout } from './components/Layouts/Layout';
import { AdminLayout } from './components/Layouts/AdminLayout';
import { IdentiyLayout } from './components/Layouts/IdentityLayout';
import { getUser, saveUser, UserContext } from './contexts/userContext'
import './custom.css';
import { BackdropLoader } from './components/BackdropLoader';

export const App = () => {
    const [user, setUser] = useState(null);
    const [signedIn, setSignedIn] = useState(false)
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        //Attempt to get out of memory
        var sesUser = getUser();


        if (sesUser !== null)
            setUser(sesUser);

        if (sesUser !== null)
            setSignedIn(true);

        setLoading(false);
    }, [])

    useEffect(() => {
        if (user) {
            setSignedIn(true);
            saveUser(user);
        }
        else {
            setSignedIn(false);
        }
    }, [user])



    //useEffect(() => {
    //    if (loading) {
    //        setLoading(true);
    //    }
    //    else {
    //        setLoading(false);
    //    }
    //}, [loading])

    //useEffect(() => {

    //}, [jwt])

    const userContextData = {
        setSignedIn,
        signedIn,
        setUser,
        user
    }

    const MainUi = () => { 
        if (loading) {
            <BackdropLoader isLoading={loading} />
        }
        else {
            if (user == null) {
                return (
                    <IdentiyLayout>
                        <Routes>
                            {AuthRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    </IdentiyLayout>
                )
            }
            else {

                if (user.userRole == "User") {
                    return (

                        <Layout>
                            <Routes>
                                {AppRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </Layout>
                    )
                }
                else if (user.userRole == "Admin") {
                    return (
                        <AdminLayout>
                            <Routes>
                                {AdminRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                            </AdminLayout>
                     )

                }
                else {

                    return (
                        <IdentiyLayout>
                            <Routes>
                                {AuthRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </IdentiyLayout>
                    )
                }



            }
        }


       }

    return (
        <UserContext.Provider value={userContextData}>
            <MainUi />          
       </UserContext.Provider>

                    );
  
}
