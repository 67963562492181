import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from '../../contexts/userContext'
import { makeAuthorizedCall } from '../../JwtTokenManager'

export const ProcessCheckout = () => {
    const { user, setUser } = React.useContext(UserContext);
    const action = new URLSearchParams(window.location.search).get(
        "action"
    );
    const promoCode = new URLSearchParams(window.location.search).get(
        "promoCode"
    );
    useEffect(() => {
        doProcessCheckout();
        console.log(JSON.stringify(user))
    }, [])

    const doProcessCheckout = async () => {

        const res = await makeAuthorizedCall(`stripe/confirm-activate-subscription?promoCode=${promoCode}`, 'post', null);
        if (res.ok) {
            const resData = await res.json();

            setUser(resData.refeshedUser);
        }
       window.location.href = '/'

    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}