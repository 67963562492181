import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { IdentityNavMenu } from '../NavMenus/IdentityNavMenu';
import bg from '../../assets/LoginBg.png'


export class IdentiyLayout extends Component {
    static displayName = IdentiyLayout.name;

    render() {
        return (
            <div>
                <IdentityNavMenu />
                <Container>
                    <div style={{
                        backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat'
                    }}>
                        {this.props.children}
                    </div>
                </Container>
            </div>
        );
    }
}
