import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { object, string } from 'yup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BackdropLoader } from '../BackdropLoader';
import { theme } from '../../muiTheme'
import Paper from '@mui/material/Paper';
import { UserContext } from '../../contexts/userContext'
import { setJwtToken, setRefreshToken } from '../../JwtTokenManager'



function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://righttool.app">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

let formSchema = object({
    name: string().required('!name|Name Is Required'),
    userName: string().required('!userName|User Name Is Required').test('is unique', '!userName|User Name Already Exists', async (value, testContext) => (await fetch(`/authenticate/user-name-unique?userName=${value}`, { method: "get" })).text !== 'true'),
    password: string().required().min(8).matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
});


export const Invite = () => {
    const { user, setUser } = React.useContext(UserContext);
    const [nameError, setNameError] = React.useState('');
    const [userNameError, setUserNameError] = React.useState('');
    const [pwEight, setPwEight] = React.useState(false);
    const [pwUpper, setPwUpper] = React.useState(false);
    const [pwNumber, setPwNumber] = React.useState(false)
    const [pwSpecial, setPwSpecial] = React.useState(false);
    const [pwLower, setPwLower] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [tokenMessage, setTokenMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [pwMatch, setPwMatch] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [confirmpassword, setConfirmPassword] = React.useState('');

    const token = new URLSearchParams(window.location.search).get(
        "token"
    );

    useEffect(() => {
        checkInvite();
    }, [])

    const checkInvite = async () => {
        const response = await fetch(`/authenticate/validate-invite?token=${token}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        })

        if (!response.ok) {
            const resData = await response.json();
            setTokenMessage("An Error Occured")
            setLoading(false);
        }
        else {
            const resData = await response.json();
            if (!resData.success) {
                setTokenMessage(resData.message)
            }
            else {
                setUserName(resData.email);
            }
            setLoading(false);
        }
        
    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        setNameError('');
        setUserNameError('');

        const data = new FormData(event.currentTarget);

        try {
            await formSchema.validate(
                {
                    name: data.get('name'),
                    userName: data.get('userName'),
                    password: data.get('password'),
                    //userRole: role
                },
                {
                    strict: true,
                    abortEarly: false
                },
            );
            if (password == confirmpassword) {
                var body = JSON.stringify(
                    {
                        name: data.get('name'),
                        userName: data.get('userName'),
                        password: data.get('password'),
                        token: token

                    });


                const res = await fetch(`authenticate/register-team-member`, {
                    method: "post",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: body
                })
                const resData = await res.json();

                setUser(resData.userInfo);
                setJwtToken(resData.token);
                setRefreshToken(resData.refreshToken);
                setLoading(false)
            }
            else {
                setLoading(false);
            }

        } catch (err) {

            console.log(JSON.stringify(err.name))

            console.log(JSON.stringify(err.errors))
            handleErrors(err.errors);
            setLoading(false);
        }
        

    };


    const passwordCheck = (event) => {
        var val = event.target.value;
        setPassword(event.target.value)

        if (val.length >= 8)
            setPwEight(true);
        else {
            setPwEight(false);
        }

        if (/[A-Z]/.test(val)) {
            setPwUpper(true)
        }
        else {
            setPwUpper(false)
        }

        if (/[a-z]/.test(val)) {
            setPwLower(true)
        }
        else {
            setPwLower(false)
        }

        if (/[!@#$%^&*()\-_=+{};:,<.>]/.test(val)) {
            setPwSpecial(true)
        }
        else {
            setPwSpecial(false)
        }

        if (/.*\d/.test(val)) {
            setPwNumber(true)
        }
        else {
            setPwNumber(false)
        }

        if (val === confirmpassword) {
            setPwMatch(true)
        }
        else {
            setPwMatch(false)
        }

    };

    const confirmCheck = (event) => {
        var val = event.target.value;
        setConfirmPassword(event.target.value)


        if (val === password) {
            setPwMatch(true)
        }
        else {
            setPwMatch(false)
        }

    };

    const userNameCheck = async (event) => {

        //here
        setUserName(event.target.value);
        var val = event.target.value;
        const res = await fetch(`authenticate/user-name-unique?userName=${val}`, {
            method: "get"
        })
        const unique = await res.text();

        console.log(`response : ${unique}`)


        if (unique === 'true') {
            setUserNameError('')
        }
        else {
            setUserNameError('User Name Already Exists')
        }
    }

    const handleErrors = (errors) => {

        errors.forEach(e => {

            if (e.startsWith('!')) {
                var message = e.substring(1);
                var messageEnum = message.split('|');

                switch (messageEnum[0]) {
                    case "userName":
                        setUserNameError(messageEnum[1]);
                        break;
                    case "name":
                        setNameError(messageEnum[1]);
                }

            }
        });
    }



   
        return (
            <ThemeProvider theme={theme}>
                <BackdropLoader isLoading={loading} />
                    <Container component="main">
                        <CssBaseline />
                        <Paper
                            elevation={3}
                            sx={{
                                marginTop: 8,
                                marginX: 20,
                                paddingY: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Accept Invite
                        </Typography>
                        {tokenMessage !== "" && !loading &&
                            <Box  fullwidth sx={{ mt: 3, mx: 5 }}>
                                <Grid container spacing={2} sx={{ mb: 5 }}>
                                    <Grid item xs={12}>
                                        <Alert severity="error">{tokenMessage}</Alert>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                    <Grid item xs>
                                        <Link href="/login" variant="body2">
                                            {"Go To Login"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        {tokenMessage == "" && !loading &&
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, mx: 5 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="name"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Name"
                                            />
                                        </Grid>
                                        {nameError != '' &&
                                            <Grid item xs={12}>
                                                <Alert severity="error">{nameError}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="userName"
                                                required
                                                fullWidth
                                                id="userName"
                                                label="User Name"
                                                value={userName }
                                                onChange={userNameCheck}
                                            />
                                        </Grid>
                                        {userNameError != '' &&
                                            <Grid item xs={12}>
                                                <Alert severity="error">{userNameError}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type="password"
                                                id="password"
                                                autoComplete="new-password"
                                                onKeyUp={passwordCheck}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type="password"
                                            id="confirmPassword"
                                            onChange={confirmCheck}
                                        />
                                    </Grid>
                                        <Grid item xs={12}>
                                            {!pwEight && <Alert severity="warning">Password Must Have 8 or More Characters</Alert>}
                                            {pwEight && <Alert severity="success">Password Must Have 8 or More Characters</Alert>}
                                            {!pwUpper && <Alert severity="warning">Password Must Contain Upper Case</Alert>}
                                            {pwUpper && <Alert severity="success">Password Must Contain Upper Case</Alert>}
                                            {!pwLower && <Alert severity="warning">Password Must Contain Lower Case</Alert>}
                                            {pwLower && <Alert severity="success">Password Must Contain Lower Case</Alert>}
                                            {!pwNumber && <Alert severity="warning">Password Must Contain A Number</Alert>}
                                            {pwNumber && <Alert severity="success">Password Must Contain A Number</Alert>}
                                            {!pwSpecial && <Alert severity="warning">Password Must Contain A Special Character</Alert>}
                                            {pwSpecial && <Alert severity="success">Password Must Contain A Special Character</Alert>}
                                            {!pwMatch && <Alert severity="warning">Password Does Not Match Confirm Password</Alert>}
                                            {pwMatch && <Alert severity="success">Password Matches Confirm Password</Alert>}
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                        }
                        </Paper>
                        <Copyright sx={{ mt: 5 }} />
                    </Container>
            </ThemeProvider>
        );
}