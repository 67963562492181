import { FetchData } from "../components/FetchData";
import { Home } from "../components/Home";
import { Logout } from "../components/Auth/Logout"
import { AccountProfile } from "../components/User/AccountProfile"
import { Team } from "../components/User/Team"
import { SilentDisconnect } from "../components/Auth/SilentDisconnect"
import { SilentConnect } from "../components/User/SilentConnect"
import { ProcessCheckout } from "../components/Checkout/ProcessCheckout"
import {DisconnectAccount } from "../components/User/DisconnectAccount"
const AppRoutes = [
  {
    index: true,
    element: <AccountProfile />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
   },
   {
       path: '/logout',
       element: <Logout />
    },
    {
        path: '/profile',
        element: <AccountProfile />
    },
    {
        path: '/disconnect-success',
        element: <DisconnectAccount />
    },
    {
        path: '/team',
        element: <Team />
    },
    {
        path: '/processCheckout',
        element: <ProcessCheckout />
    },
    {
        path: '/qbo-disconnect',
        element: <SilentDisconnect />
    },
    {
        path: '/qbo-silent-connect',
        element: <SilentConnect />
    },
];

export default AppRoutes;
