import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { object, string } from 'yup';
import { theme } from '../../muiTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BackdropLoader } from '../BackdropLoader';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://righttool.app">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

let formSchema = object({
    password: string().required().min(8).matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
});

export const PasswordReset = () => {

    const [password, setPassword] = React.useState('');
    const [confirmpassword, setConfirmPassword] = React.useState('');
    const [pwEight, setPwEight] = React.useState(false);
    const [pwUpper, setPwUpper] = React.useState(false);
    const [pwNumber, setPwNumber] = React.useState(false)
    const [pwSpecial, setPwSpecial] = React.useState(false);
    const [pwLower, setPwLower] = React.useState(false);
    const [pwMatch, setPwMatch] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [resetMessage, setResetMessage] = useState('');
    const [resetComplete, setResetComplete] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(true);

    const resetToken = new URLSearchParams(window.location.search).get(
        "resetToken"
    );
    const [isValidReset, setisValidReset] = useState(false)

    useEffect(() => {
        validateToken();
    }, [])

    const validateToken = async () => {

        const res = await fetch(`authenticate/validate-password-reset?resetToken=${resetToken}`, {
            method: "get",
            headers: {
                'Content-Type': 'application/json'
            },

        })

        if (res.ok) {
            const resData = await res.json();

            if (resData.success) {
                setisValidReset(true);
            }
        }
        setLoading(false);


    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        let thing = encodeURI(data.get('password'))

        try {
            await formSchema.validate(
                {
                    password: data.get('password'),
                },
                {
                    strict: true,
                    abortEarly: false
                },
            );


            const res = await fetch(`authenticate/reset-password?password=${encodeURIComponent(data.get('password'))}&resetToken=${resetToken}`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            if (res.ok) {
                const resData = await res.json();

                if (!resData.success) {
                    setResetSuccess(false)
                }
                setResetMessage(resData.message)
                setResetComplete(true)
                setLoading(false)
                window.location.href = '/';
            }
            else {
                setResetSuccess(false)
                setResetMessage("An Error Occured During Reset. Please Try Again")
                setResetComplete(true)
                setLoading(false)
            }

        } catch (err) {
            setLoading(false);
        }

    };

    const passwordCheck = (event) => {
        var val = event.target.value;
        setPassword(event.target.value)

        if (val.length >= 8)
            setPwEight(true);
        else {
            setPwEight(false);
        }

        if (/[A-Z]/.test(val)) {
            setPwUpper(true)
        }
        else {
            setPwUpper(false)
        }

        if (/[a-z]/.test(val)) {
            setPwLower(true)
        }
        else {
            setPwLower(false)
        }

        if (/[!@#$%^&*()\-_=+{};:,<.>]/.test(val)) {
            setPwSpecial(true)
        }
        else {
            setPwSpecial(false)
        }

        if (/.*\d/.test(val)) {
            setPwNumber(true)
        }
        else {
            setPwNumber(false)
        }

        if (val === confirmpassword) {
            setPwMatch(true)
        }
        else {
            setPwMatch(false)
        }

    };

    const confirmCheck = (event) => {
        var val = event.target.value;
        setConfirmPassword(event.target.value)


        if (val === password) {
            setPwMatch(true)
        }
        else {
            setPwMatch(false)
        }

    };

    

    return (
        <ThemeProvider theme={theme}>
            <BackdropLoader isLoading={loading} />
            <Container component="main">
                <CssBaseline />
                <Paper elevation={3}
                    sx={{
                        marginTop: 8,
                        marginX: 20,
                        paddingY: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    {!isValidReset && !loading && 
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, mx: 5 }}>
                            <Grid container spacing={2} sx={{mb:5}}>
                                <Grid item xs={12}>
                                    <Alert severity="error">This link has expired or is invalid. Login or Register using the links below.</Alert>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/login" variant="body2">
                                        {"Go To Login"}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/register" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {isValidReset && !loading &&
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, mx: 5 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        name="password"
                                        type="password"
                                        onChange={passwordCheck}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        onChange={confirmCheck}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!pwEight && <Alert severity="warning">Password Must Have 8 or More Characters</Alert>}
                                    {pwEight && <Alert severity="success">Password Must Have 8 or More Characters</Alert>}
                                    {!pwUpper && <Alert severity="warning">Password Must Contain Upper Case</Alert>}
                                    {pwUpper && <Alert severity="success">Password Must Contain Upper Case</Alert>}
                                    {!pwLower && <Alert severity="warning">Password Must Contain Lower Case</Alert>}
                                    {pwLower && <Alert severity="success">Password Must Contain Lower Case</Alert>}
                                    {!pwNumber && <Alert severity="warning">Password Must Contain A Number</Alert>}
                                    {pwNumber && <Alert severity="success">Password Must Contain A Number</Alert>}
                                    {!pwSpecial && <Alert severity="warning">Password Must Contain A Special Character</Alert>}
                                    {pwSpecial && <Alert severity="success">Password Must Contain A Special Character</Alert>}
                                    {!pwMatch && <Alert severity="warning">Password Does Not Match Confirm Password</Alert>}
                                    {pwMatch && <Alert severity="success">Password Matches Confirm Password</Alert>}
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Reset Password
                            </Button>
                            {resetComplete && 
                                <Grid item xs={12}>
                                    <Alert severity={resetSuccess == true ? "success" : "error" }>{resetMessage}</Alert>
                                </Grid>
                            }

                            <Grid container>
                                <Grid item xs>
                                    <Link href="/login" variant="body2">
                                        {"Go To Login"}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/register" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Paper>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );

}