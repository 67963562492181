import { createContext, useEffect, useState } from 'react';
export const UserContext = createContext({ user: {}, signedIn: false });

//export const UserContextProvider = ({ children }) => {
//    const [user, setUser] = useState();
//    const [signedIn, setSignedIn] = useState(null);
//    const [loading, setLoading] = useState();

//    useEffect(async () => {
//        var sesUser = getUser();

//        if (sesUser !== null)
//            setUser(sesUser);

//        if (sesUser !== null)
//            setSignedIn(true);
//    }, []);

//    const userContextData = {
//        setSignedIn,
//        signedIn,
//        setUser,
//        user,
//        loading,
//        setLoading
//    }

//    return (
//        <UserContext.Provider value={userContextData}>
//            {children}
//        </UserContext.Provider>
//    );
//};

export function getUser() {
    var user = localStorage.getItem("rt_user")
    return JSON.parse(user);
}

export function saveUser(user) {
    localStorage.setItem("rt_user", JSON.stringify(user))
}

export function deleteUser() {
    localStorage.removeItem("rt_user");
}
