import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { setJwtToken, setRefreshToken } from '../../JwtTokenManager'

export const SilentDisconnect = () => {
    let userStore = localStorage.getItem("rt_user")
    let user = JSON.parse(userStore);
    useEffect(() => {
        doSilentDisconnect();

    }, [])

    const doSilentDisconnect = async () => {

        const res = await fetch(`authenticate/qbo-disconnect?username=${user.userName}`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json'
            },

        }).then(() => {
            localStorage.clear();
            window.location.href = "/"
        })

    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}