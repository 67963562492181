import React, { useEffect } from 'react';
import { deleteUser, UserContext } from '../../contexts/userContext'
import { deleteJwtToken, deleteRefreshToken } from '../../JwtTokenManager'
import { Login } from '../Auth/Login'
export const Logout = () => {
    const { user, setUser } = React.useContext(UserContext);


    useEffect(() => {
        deleteUser();
        deleteJwtToken();
        deleteRefreshToken();
        setUser(null);
        
    }, [])


    return (
        <Login/>
    );

}