import React, { useEffect, useState, useContext } from 'react';
import { makeAuthorizedCall } from '../../JwtTokenManager'
import { BackdropLoader } from '../BackdropLoader';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { UserContext } from '../../contexts/userContext'
import { Login } from '../Auth/Login'
import { loadStripe } from '@stripe/stripe-js';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ProductSelection } from '../Checkout/ProductSelection'
import { Checkout } from '../Checkout/Checkout'
import { Elements } from "@stripe/react-stripe-js";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://righttool.app">
                RightTool
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export const AccountProfile = ({ navigation }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [profile, setProfile] = useState({});
    const { user, setUser } = useContext(UserContext);
    const [stripePromise, setStipePromise] = useState(null);
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [editDetails, setEditDetails] = useState(false);
    const [editName, setEditName] = useState('');
    const [priceId, setPriceId] = useState(null)
    const [stripeProducts, setStripeProducts] = useState(null);
    const [clientSecret, setClientSecret] = useState(null)
    const [openSubPopup, setSubPopup] = useState(false)
    const [paymentAmount, setPaymentAmount] = useState('$50/mo')
    const [promoCodes, setPromoCodes] = useState(null)
    const [trial, setTrial] = useState(false)

    useEffect(() => {
        
        const sessionId = new URLSearchParams(window.location.search).get(
            "session_id"
        );

        getAccountProfile(sessionId);

    }, [])

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const getAccountProfile = async (sessionId) => {
        const response = await makeAuthorizedCall('profile/get-profile', 'get', null);
        const data = await response.json();
        setProfile(data.profile);
        setEditName(data.profile.customerName)
        setStipePromise(loadStripe(data.publishableKey));
        setStripeProducts(data.profile.stripeProducts);
        setIsLoading(false);

        if (sessionId !== null) {
            fetch(`stripe/update-subscription-card?setupIntent=${sessionId}`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
            })

        }
    }

    const updatePayment = async () => {
        const response = await makeAuthorizedCall('stripe/get-payment-session', 'get', null);
        const data = await response.json();
        console.log(JSON.stringify(data))
        const stripe = await stripePromise;
        var sessionId = data.session;

        const { error } = await stripe.redirectToCheckout({
            sessionId
        });
    }

    const handleCancelClose = () => {
        setOpenCancelPopup(false);
    };

    const handleCancelOpen = () => {
        setOpenCancelPopup(true);
    };

    const cancelSubscription = async () => {

        var feedback = cancelReason == "" ? "No Feedback": cancelReason;

        const response = await makeAuthorizedCall(`stripe/cancel-subscription?feedBack=${feedback}`, 'get', null);
        const data = await response.json();

        if (data.success) {
            window.location.href = '/logout';
        }
        else {

        }
    }

    const goToTeam = () => {
        window.location.href = '/team'
    }

    const handleChange = (event) => {
        setCancelReason(event.target.value);
    };

    const handleEditNameChange = (event) => {
        setEditName(event.target.value);
    };

    const toggleEditName = (event) => {
        setEditDetails(!editDetails);
    }

    const updateTeamName = async(event) => {
        const response = await makeAuthorizedCall(`teamapi/update-team-name?name=${editName}`, 'get', null);

        window.location.reload();
    }

    const CancelPopUp = () => {
        return (
            <Dialog open={openCancelPopup} onClose={handleCancelClose}>
                <DialogTitle>Thank you for giving us a chance to serve you</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We hate to see you go but we respect your decision! Before you go, can you provide us some honest feedback on why you decided to deactivate your account?
                    </DialogContentText>
                    <TextField
                        
                        margin="dense"
                        label="Feedback"
                        multiline
                        fullWidth
                        value={cancelReason}
                        onChange={handleChange }
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose}>Nevermind</Button>
                    <Button onClick={cancelSubscription}>Cancel My Subscription</Button>
                </DialogActions>
            </Dialog>
            )
  
    }

    //const DirectionsPopup = () => {
    //    return (
    //        <Dialog open={openCancelPopup} onClose={handleCancelClose}>
    //            <DialogTitle>Having Trouble Using Your PRO Features Start Here</DialogTitle>
    //            <DialogContent>
                        
    //            </DialogContent>
    //            <DialogActions>
    //                <Button onClick={handleCancelClose}>OK</Button>
    //            </DialogActions>
    //        </Dialog>
    //    )

    //}

    const ActivateSubscription = async() => {
        if (clientSecret == null) {
            const response = await makeAuthorizedCall(`stripe/activate-subscription?priceid=${priceId}`, 'post', null);
            const data = await response.json();
            setClientSecret(data.clientSecret);
            setPaymentAmount(`${data.paymentAmount}/mo`);

            if (data.promoCodes) {
                setPromoCodes(data.promoCodes);
                setTrial(true);
            }
        }

        handleActivateOpen();
    }


    const handleActivateClose = () => {
        setSubPopup(false);
    };

    const handleActivateOpen = () => {
        setSubPopup(true);
    };

    const appearance = {
        theme: 'flat',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const openConnectWithIntuit = () => {
        window.location.replace("/authenticate/initiate-qbo-auth-link");
    }

    const openDisconnectWithIntuit = () => {
        if (window.confirm('Are you sure you want to remove the ability to login with your Intuit Account?')) {
            setIsLoading(true);
        fetch(`/authenticate/qbo-disconnect?username=${user.userName}`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(data => {
                //localStorage.clear();
            user.qboConnected = false;
            console.log(user.qboConnected);
                setUser(user);

                window.location.href = '/disconnect-success';
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false);
            })
        }
    }

    const SelectProduct = async(productId, priceLabel) => {
        console.log(`product selected ${productId}`)

        if (clientSecret == null) {
            const response = await makeAuthorizedCall(`stripe/activate-subscription?priceid=${productId}`, 'post', null);
            const data = await response.json();
            setClientSecret(data.clientSecret);

            setPriceId(productId);
            setPaymentAmount(priceLabel); 
            if (data.promoCodes) {
                setPromoCodes(data.promoCodes);
                setTrial(true);
            }
        }
    }

    const ActivateSubscriptionPopUp = () => {
        return (
            <Dialog open={openSubPopup} onClose={handleActivateClose} fullWidth maxWidth='lg'>
                <DialogTitle>Activate Your Subscription</DialogTitle>
                <DialogContent>
                    {priceId == null && <ProductSelection OnProductSelect={SelectProduct} ProductTiers={stripeProducts} />}
                    {priceId != null &&
                        <Elements stripe={stripePromise} options={options}>
                            <Checkout StripePromise={stripePromise} ClientSecret={clientSecret} PriceLabel={paymentAmount} RegisterPage={false} PromoCodes={promoCodes} Trial={trial} PriceId={priceId} />
                        </Elements>
                    }

                </DialogContent>
            </Dialog>
        )

    }


    if (user == null)
        return (<Login />);

    return (
        <div>
            {
                isLoading &&
                <BackdropLoader isLoading={isLoading } />
            }
            {
                !isLoading &&
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        {/* Chart */}
                            {user.admin && 
                                <Grid item xs={12} md={6} lg={7}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 470,
                                        }}
                                    >

                                        <Typography component="h2" variant="h3" color="text.primary" gutterBottom align="center">
                                            Welcome To RightTool
                                        </Typography>
                                        <Divider />
                                        {(user.freeUser == true) &&
                                            <div style={{ marginTop: 20 }}>
                                                <Typography component="h6" variant="h6" gutterBottom align="center">
                                                    You have been provided a FREE account for your team.
                                                </Typography>
                                            </div>
                                        }
                                        {((user.subscriptionStanding == 'canceled' || user.subscriptionStanding == 'cancelled') && user.freeUser == false) &&
                                            <div style={{ marginTop: 20 }}>
                                                <Typography component="h6" variant="h6" gutterBottom align="center">
                                                    You do not currently have an active PRO account. If you would like to use PRO features please upgrade your account.
                                                </Typography>

                                                <Typography component="h6" variant="h6" gutterBottom align="center" sx={{paddingTop:5} }>
                                                    Even without a PRO you will continue to enjoy our FREE features!
                                                </Typography>
                                            </div>
                                        }
                                        {((user.subscriptionStanding != 'canceled' && user.subscriptionStanding != 'cancelled') && user.freeUser == false)  &&
                                            <div style={{ marginTop: 20 }}>
                                                <Typography component="h6" variant="h6" gutterBottom align="center">
                                                    Your account is up to date! All PRO features will be unlocked in your chrome extension.
                                                </Typography>
                                            </div>
                                        }
                                        {(!user.qboConnected) &&
                                            <div style={{marginTop: '15px'}}>
                                                <Typography component="h6" variant="h4" gutterBottom align="center">
                                                    Connect to QuickBooks Online to enable logging in with your Intuit Account!
                                                </Typography>
                                            <img style={{ width: '45%', display: 'block', cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto' }}
                                                src={require('../../assets/connectQB.svg').default}
                                                onMouseOver={e => e.currentTarget.src = require('../../assets/connectQBHover.svg').default}
                                                onMouseOut={e => e.currentTarget.src = require('../../assets/connectQB.svg').default}
                                                alt='Connect your QBO Account'
                                                onClick={openConnectWithIntuit} />
                                            </div>
                                        }
                                        {(user.qboConnected) &&
                                            <div style={{ marginTop: 60 }}>
                                                <Typography component="h6" variant="h6" gutterBottom align="center">
                                                    Want to disconnect your Intuit Account? 
                                                </Typography>
                                                <Button
                                                    onClick={openDisconnectWithIntuit}
                                                    style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>Disconnect QBO Login</Button>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            }
                            {!user.admin &&
                                <Grid item xs={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 470,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary" gutterBottom align="center">
                                            Welcome To RightTool
                                        </Typography>
                                        <Divider />
                                        {(user.freeUser == true) &&
                                            <div style={{ marginTop: 20 }}>
                                                <Typography component="h6" variant="h6" gutterBottom align="center">
                                                    You have been provided a FREE account for your team.
                                                </Typography>
                                            </div>
                                        }
                                        {(user.subscriptionStanding == 'canceled' || user.subscriptionStanding == 'cancelled') &&
                                            <div style={{ marginTop: 20 }}>
                                                <Typography component="h6" variant="h6" gutterBottom align="center">
                                                    This is not currently have an active PRO account.
                                                </Typography>

                                                <Typography component="h6" variant="h6" gutterBottom align="center" sx={{ paddingTop: 5 }}>
                                                    Even without a PRO you will continue to enjoy our FREE features!
                                                </Typography>
                                                <Typography component="h6" variant="h6" gutterBottom align="center" sx={{ paddingTop: 5 }}>
                                                    Your Team Manager is {profile.profileManager}, please contact them to manage your RightTool Team.
                                                </Typography>
                                            </div>
                                        }
                                        {(user.subscriptionStanding != 'canceled' && user.subscriptionStanding != 'cancelled') &&
                                            <div style={{ marginTop: 20 }}>
                                                <Typography component="h6" variant="h6" gutterBottom align="center">
                                                    Welcome to RightTool PRO! All PRO features will be unlocked in your chrome extension.
                                                </Typography>
                                                <Typography component="h6" variant="h6" gutterBottom align="center" sx={{ paddingTop: 10 }}>
                                                    Your Team Manager is {profile.profileManager}, please contact them to manage your RightTool Team.
                                                </Typography>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            }
                            {user.admin && 
                                <Grid item xs={12} md={6} lg={5}>
                                    {/* Subscription */}
                                    {(user.subscriptionStanding == 'canceled' || user.subscriptionStanding == 'cancelled') &&
                                        <Card
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                minheight: 235,
                                            }}
                                        >
                                            <CardContent>
                                                <Typography component="h2" variant="h3" color="text.primary" gutterBottom align="center">
                                                    Your Subscription Is Not Active
                                                </Typography>
                                            </CardContent>
                                            <Divider />
                                            <CardActions>
                                                <Button fullWidth variant="outlined" onClick={() => handleActivateOpen()}>Activate Subscription</Button>
                                            </CardActions>
                                        </Card>
                                    }
                                    {(user.subscriptionStanding != 'canceled' && user.subscriptionStanding != 'cancelled') &&
                                        <div>
                                            <Card
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    minheight: 235,
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom align="center">
                                                        {profile.billInterval == "month" ? "RightTool Monthly Subscription" : "RightTool Yearly Subscription"}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'baseline',
                                                            mb: 2,
                                                        }}
                                                    >
                                                        <Typography component="h2" variant="h3" color="text.primary">
                                                            {profile.billPrice}/
                                                        </Typography>
                                                        <Typography variant="h6" color="text.secondary">
                                                            {profile.billInterval == "month" ? "mo" : "yr"}
                                                        </Typography>
                                                    </Box>
                                                    <Typography align="right">
                                                        Next Bill Due: {profile.nextBillDate}
                                                    </Typography>
                                                </CardContent>
                                                <Divider />
                                                <CardActions>
                                                    <Button fullWidth variant="outlined" onClick={() => handleCancelOpen()}>Cancel Subscription</Button>
                                                    <Button fullWidth variant="outlined" onClick={() => openInNewTab("https://billing.stripe.com/p/login/4gw02S4Vc63kfcs9AA")}>Manage Billing</Button>
                                                </CardActions>
                                            </Card>
                                            <Card
                                                sx={{
                                                    p: 2,
                                                    mt: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height: 235,
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom align="center">
                                                        Team
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'baseline',
                                                            mb: 2,
                                                        }}
                                                    >
                                                        {editDetails &&
                                                            <TextField

                                                                margin="dense"
                                                                label="Team Name"
                                                                multiline
                                                                fullWidth
                                                                value={editName}
                                                                onChange={handleEditNameChange}
                                                                variant="standard"
                                                            />
                                                        }
                                                        {!editDetails &&
                                                            <Typography component="h3" variant="h4" color="text.primary" noWrap>
                                                                {profile.customerName}
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </CardContent>
                                                <Divider />
                                                {!editDetails &&
                                                    <CardActions>
                                                        <Button fullWidth variant="outlined" onClick={() => toggleEditName()}>Edit Details</Button>
                                                        <Button fullWidth variant="outlined" onClick={() => goToTeam()}>PRO Members</Button>
                                                    </CardActions>
                                                }
                                                {editDetails &&
                                                    <CardActions>
                                                        <Button fullWidth variant="contained" onClick={() => updateTeamName()}>Save</Button>
                                                        <Button fullWidth variant="outlined" onClick={() => toggleEditName()}>Never Mind</Button>
                                                    </CardActions>
                                                }
                                            </Card>
                                        </div>
                                        
                                    }


                                </Grid>
                            }
                          <Grid item xs={12}>
                                <Card sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
                                    <div style={{ marginTop: '15px' }}>
                                        <Typography component="h6" variant="h4" gutterBottom align="center">
                                            Don't have the RightTool extension installed?
                                        </Typography>
                                        
                                    </div>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Button style={{ marginLeft: 'auto', marginRight: 'auto' }} variant="contained" onClick={() => openInNewTab("https://chrome.google.com/webstore/detail/righttool-for-quickbooks/ocgmjfcfjilnmobeocoklcbpcanilpjd")}>Go To Download!</Button>
                                    </Box>
                                </Card>
                         </Grid>
                        {/* Recent Orders */}
                        <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#193048' }}>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            }
            {CancelPopUp()}
            {ActivateSubscriptionPopUp() }
        </div>
    );

}