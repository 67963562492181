import React, { useEffect, useState } from 'react';
import { makeAuthorizedCall } from '../JwtTokenManager'

export const FetchData = ({ navigation }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [forecasts, setForcasts] = useState({});

    useEffect(() => {
        populateWeatherData();
    }, [])

    const populateWeatherData = async () => {
        const response = await makeAuthorizedCall('weatherforecast', 'get', null);
        const data = await response.json();
            setForcasts(data);
            setIsLoading(false);

    }



    const renderForecastsTable = (forecasts) => {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Temp. (C)</th>
                        <th>Temp. (F)</th>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {forecasts.map(forecast =>
                        <tr key={forecast.date}>
                            <td>{forecast.date}</td>
                            <td>{forecast.temperatureC}</td>
                            <td>{forecast.temperatureF}</td>
                            <td>{forecast.summary}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    return (
        <div>
            {
                isLoading &&
                <p><em>Loading...</em></p>
            }
            {
                !isLoading &&
                <div>
                    <h1 id="tabelLabel" >Weather forecast</h1>
                    <p>This component demonstrates fetching data from the server.</p>
                        {renderForecastsTable(forecasts)}
                </div>
            }
        </div>
    );

}