import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeAuthorizedCall } from '../../JwtTokenManager'
import { BackdropLoader } from '../BackdropLoader';
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import { object, string } from 'yup';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';




let formSchema = object({
    name: string().required('!name|Name Is Required'),
    email: string().email('!email|Email Is Not In A Correct Format').required('!email|Email Is Required'),
});

export const Team = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [team, setTeam] = useState([]);
    const [customerName, setCustomerName] = useState("");

    const [openAddPopup, setOpenAddPopup] = useState(false);
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [addTeamAdmin, setAddTeamAdmin] = useState(false);
    const [addFreeUser, setAddFreeUser] = useState(false);


    const [currentTeamMemberId, setCurrentTeamMemberId] = useState(0);

    const [openUpdatePopup, setUpdatePopup] = useState(false);
    const [updateTeamName, setUpdateTeamName] = useState('');
    const [updateTeamAdmin, setUpdateTeamAdmin] = useState(false);
    const [updateTeamFree, setUpdateTeamFree] = useState(false);


    const [openDeletePopup, setDeletePopup] = useState(false);

    const [inviteSentPopup, setInviteSentPopup] = useState(false);


    const columns = [
        { field: 'name', headerName: 'Name', flex: .5 },
        { field: 'email', headerName: 'Email', flex: .5 },
        {
            field: 'id', headerName: 'Actions', width: 200, renderCell: (params) => (

                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Tooltip title="Update Team Member">
                        <IconButton
                            size="medium"
                            sx={{ ml: 2, backgroundColor: 'primary.main' }}
                            onClick={() => { handleUpdateClick(params.row) }}
                        >
                            <UpgradeIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Team Member">
                        <IconButton
                            size="medium"
                            sx={{ ml: 2, backgroundColor: 'primary.main' }}
                            onClick={() => { handleDeleteClick(params.row) } }
                        >
                            <DeleteIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        },
        {
            field: 'invitAccepted', headerName: 'Invite Accepted', width: 150, renderCell: (params) => (
                <div>
                    {params.value == true &&
                        <Alert severity= "success">Accepted</Alert>
                    }
                    {params.value !== true &&
                        <Tooltip title="Click To Send Team Member Invite">
                            <Alert severity="warning" onClick={() => { handleInvite(params.row) }}>Pending <ForwardToInboxIcon/></Alert>
                        </Tooltip>
                    }
                </div>
                
            )
        },
        {
            field: 'admin', headerName: 'Admin', width: 150, renderCell: (params) => (

                <div>
                    {params.value === true &&
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <AdminPanelSettingsIcon />
                        </Avatar>
                    }
                </div>
            )
        },
        {
            field: 'freeUser', headerName: 'Account Type', width: 150, renderCell: (params) => (

                <div>
                    {params.value === true &&
                        <Paper sx={{ backgroundColor: '#193048'}}>
                            <Typography sx={{ color: 'white', margin: 2 }} size="small">FREE</Typography>
                        </Paper>
                    }
                    {params.value === false &&
                        <Paper sx={{ backgroundColor: '#193048' }}>
                            <Typography sx={{ color: 'white', margin: 2 }} size="small">PRO</Typography>
                        </Paper>
                    }
                </div>
            )
        },

    ];

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pl: 1.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter />
            </Box>
        );
    }

    useEffect(() => {

        getTeam();

    }, [])

    const getTeam = async () => {
        const response = await makeAuthorizedCall('teamapi/get-team-page', 'get', null);
        const data = await response.json();
        setTeam(data.team.customerMembers);
        setCustomerName(data.team.customerName)
        setIsLoading(false);
    }

    const handleAdd = async (event) => {
        setIsLoading(true)
        event.preventDefault();
        setOpenAddPopup(false);


        setNameError('');
        setEmailError('');


        const data = new FormData(event.currentTarget);

        try {
            await formSchema.validate(
                {
                    name: data.get('name'),
                    email: data.get('email'),
                },
                {
                    strict: true,
                    abortEarly: false
                },
            );

            var body = JSON.stringify(
                {
                    name: data.get('name'),
                    email: data.get('email'),
                    admin: addTeamAdmin,
                    freeUser: addFreeUser
                });


            const response = await makeAuthorizedCall(`teamapi/save-team-member`, 'post', body);

            //reload the grid after adding a member
            getTeam();

        } catch (err) {
            //err.name; // => 'ValidationError'
            //err.errors; // => ['Deve ser maior que 18']
            console.log(JSON.stringify(err.name))

            console.log(JSON.stringify(err.errors))
            handleErrors(err.errors);
            setIsLoading(false);
        }

    };

    const handleUpdate = async (event) => {
        setIsLoading(true)
        event.preventDefault();
        setUpdatePopup(false);

        setNameError('');
        setEmailError('');

        if (updateTeamName == "" || updateTeamName == null) {
            handleErrors(['!name|Name Is Required'])
        }
        else {
            var body = JSON.stringify(
                {
                    id: currentTeamMemberId,
                    name: updateTeamName,
                    admin: updateTeamAdmin,
                    freeUser: updateTeamFree
                });


            await makeAuthorizedCall(`teamapi/update-team-member`, 'post', body);

            //reload the grid after adding a member
            getTeam();
        }

    };

    const handleDelete = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        setDeletePopup(false);

            var body = JSON.stringify(
                {
                    id: currentTeamMemberId,
                });


             await makeAuthorizedCall(`teamapi/delete-team-member`, 'post', body);

            //reload the grid after delete a member
            getTeam();
            setDeletePopup(false);

    };

    const handleInvite = async (teamMember) => {

        setIsLoading(true);

        var body = JSON.stringify(
            {
                id: teamMember.id,
            });


        await makeAuthorizedCall(`teamapi/invite-team-member`, 'post', body);
        setIsLoading(false);
        handleInviteSentOpen();

    };

    const handleInviteSentOpen = () => {
        setInviteSentPopup(true);
    }

    const handleInviteSentClose = () => {
        setInviteSentPopup(false)
    }


    const InviteTeamMemberPopUp = () => {
        return (
            <Dialog open={inviteSentPopup} onClose={handleInviteSentClose}>

                <DialogContent>
                    <Typography component="h1" variant="h5">
                        Invite Sent!
                    </Typography>
                    <Box sx={{ mt: 3, mx: 5 }}>
                        <DialogActions>
                            <Button onClick={handleInviteSentClose}>Dismiss</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        )

    }

    const handleErrors = (errors) => {

        errors.forEach(e => {

            if (e.startsWith('!')) {
                var message = e.substring(1);
                var messageEnum = message.split('|');

                switch (messageEnum[0]) {
                    case "email":
                        setEmailError(messageEnum[1]);
                        break;
                    case "name":
                        setNameError(messageEnum[1]);
                        break;
                }

            }
        });
    }



    const handleAddOpen = () => {
        setOpenAddPopup(true);
    }

    const handleAddClose = () => {
        setOpenAddPopup(false)
        setNameError('');
        setEmailError('');
    }

    const handleAddTeamAdminClick = () => {
        setAddTeamAdmin(!addTeamAdmin);
    }

    const handleAddTeamFreeClick = () => {
        setAddFreeUser(!addFreeUser);
    }


    const AddTeamMemberPopUp = () => {
        return (
            <Dialog open={openAddPopup} onClose={handleAddClose}>
                {/*<DialogTitle>Thank you for giving us a chance to serve you</DialogTitle>*/}
                <DialogContent>
                    <Typography component="h1" variant="h5">
                        Add Team Member
                    </Typography>
                    <DialogContentText sx={{paddingTop: 2} }>
                        <strong>Reminder:</strong> Adding a pro team member will result in additional charge of $15 a month per pro team member. You will not be charged for free members.
                    </DialogContentText>
                    <Box component="form" noValidate onSubmit={handleAdd} sx={{ mt: 3, mx: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                />
                            </Grid>
                            {nameError != '' &&
                                <Grid item xs={12}>
                                    <Alert severity="error">{nameError}</Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            {emailError != '' &&
                                <Grid item xs={12}>
                                    <Alert severity="error">{emailError}</Alert>
                                </Grid>
                            }
                            <FormControlLabel control={<Checkbox id="admin" name="admin" checked={addTeamAdmin} onChange={handleAddTeamAdminClick} />} label="Admin" sx={{ paddingLeft: 2 }} />
                            <FormControlLabel control={<Checkbox id="freeUser" name="freeUser" checked={addFreeUser} onChange={handleAddTeamFreeClick} />} label="Free User" sx={{ paddingLeft: 2 }} />

                        </Grid>
                        <DialogActions>
                            <Button onClick={handleAddClose}>Nevermind</Button>
                            <Button variant="contained" type="submit">Add PRO Member</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        )

    }


    const handleUpdateClick = (teamMember) => {

        setCurrentTeamMemberId(teamMember.id);
        setUpdateTeamName(teamMember.name);
        setUpdateTeamAdmin(teamMember.admin);
        setUpdateTeamFree(teamMember.freeUser);
        handleUpdateOpen();

    };

    const handleUpdateOpen = () => {
        setUpdatePopup(true);
    }

    const handleUpdateClose = () => {
        setUpdatePopup(false)
        setNameError('');
        setCurrentTeamMemberId(0);
    }

    const handleUpdateTeamAdminClick = () => {
        setUpdateTeamAdmin(!updateTeamAdmin);
    }

    const handleUpdateTeamFreeClick = () => {
        setUpdateTeamFree(!updateTeamFree);
    }

    const handleUpdateNameChange = (event) => {
        setUpdateTeamName(event.target.value);
    };

    const UpdateTeamMemberPopUp = () => {
        return (
            <Dialog open={openUpdatePopup} onClose={handleUpdateClose}>

                <DialogContent>
                    <Typography component="h1" variant="h5">
                        Update Team Member
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleUpdate} sx={{ mt: 3, mx: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    required
                                    fullWidth
                                    value={updateTeamName}
                                    onChange={handleUpdateNameChange}
                                    label="Name"
                                />
                            </Grid>
                            {nameError != '' &&
                                <Grid item xs={12}>
                                    <Alert severity="error">{nameError}</Alert>
                                </Grid>
                            }
                            <FormControlLabel control={<Checkbox id="admin" name="admin" checked={updateTeamAdmin} onChange={handleUpdateTeamAdminClick} />} label="Admin" sx={{ paddingLeft: 2 }} />
                            <FormControlLabel control={<Checkbox id="free" name="free" checked={updateTeamFree} onChange={handleUpdateTeamFreeClick} />} label="Free User" sx={{ paddingLeft: 2 }} />

                        </Grid>
                        <DialogActions>
                            <Button onClick={handleUpdateClose}>Nevermind</Button>
                            <Button variant="contained" type="submit">Update Team Member</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        )

    }

    const handleDeleteClick = (teamMember) => {

        setCurrentTeamMemberId(teamMember.id);
        handleDeleteOpen();

    };

    const handleDeleteOpen = () => {
        setDeletePopup(true);
    }

    const handleDeleteClose = () => {
        setDeletePopup(false)
        setCurrentTeamMemberId(0);
    }


    const DeleteTeamMemberPopUp = () => {
        return (
            <Dialog open={openDeletePopup} onClose={handleDeleteClose}>

                <DialogContent>
                    <Typography component="h1" variant="h5">
                        Are You Sure Want To Delete This Team Member?
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleDelete} sx={{ mt: 3, mx: 5 }}>
                        <DialogActions>
                            <Button onClick={handleDeleteClose}>Nevermind</Button>
                            <Button variant="contained" type="submit">Delete Team Member</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        )

    }

    return (
        <div>
            {
                isLoading &&
                <BackdropLoader isLoading={isLoading} />
            }
            <Container disableGutters fullWidth>
                    <Typography
                        component="h1"
                        variant="h2"
                    align="left"
                        color="text.main"
                    >
                    {customerName} Team
                    </Typography>
            </Container>
            <Grid item xs={12} sx={{ py: 2 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row', backgroundColor: '#193048', justifyContent: "flex-end" }}>
                    <Button variant="outlined" sx={{ color: 'white' }} size="small" onClick={handleAddOpen }>Add Team Member</Button>
                </Paper>
            </Grid>
            <div style={{ height: 500, width: '100%' }}>

                <DataGrid
                    rows={team}
                    columns={columns}
                    components={{ Toolbar: QuickSearchToolbar }}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            color: 'primary.main',
                            backgroundColor: 'lightgray'
                        },
                    }}
                />
            </div>
            {AddTeamMemberPopUp()}
            {UpdateTeamMemberPopUp()}
            {DeleteTeamMemberPopUp()}
            {InviteTeamMemberPopUp() }
         </div>

        );
}