
import { Imports } from "../components/Admin/Imports"
import { Logout } from "../components/Auth/Logout"

const AdminRoutes = [
    {
        index: true,
        element: <Imports />
    },
    {
        path: '/logout',
        element: <Logout />
    },


];

export default AdminRoutes;
